import { AutocompleteOption } from '../Components/types'
import { Person } from '../api/types'

export function mapOptionToPerson(option: AutocompleteOption): Person {
    const name = option.value.split(' ')
    if (name.length === 1) {
        return {
            id: option.id,
            firstname: name[0],
            lastname: 'Unbekannt',
        }
    }

    if (name.length === 2) {
        return {
            id: option.id,
            firstname: name[0],
            lastname: name[1],
        }
    }

    const firstname = name.slice(0, -1).join(' ')
    const lastname = name[name.length - 1]

    return {
        id: option.id,
        firstname,
        lastname,
    }
}
