import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Collection } from '../../types'
import { userStore } from '../../../App'

const GET_COLLECTIONS = gql`
    query getCollections {
        getCollections {
            result
            error
            data {
                id
                title
                description
                created_at
                updated_at
                created_by_user_id
                updated_by_user_id
                public
            }
        }
    }
`

export default function useGetCollections(): [
    Collection[] | null,
    Dispatch<SetStateAction<Collection[] | null>>
] {
    const { isLoggedIn } = userStore
    const [collections, setCollections] = useState<Collection[] | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_COLLECTIONS)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback().then((res) => {
                if (res?.data?.getCollections?.data) {
                    setCollections(res.data.getCollections.data)
                }
            })
        }
        return () => {
            setCollections(null)
        }
    }, [isLoggedIn])

    return [collections, setCollections]
}
