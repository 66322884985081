import { makeAutoObservable } from 'mobx'

export class ResponseStore {
    successMessage: string = ''

    errorMessage: string = ''

    warningMessage: string = ''

    informationMessage: string = ''

    open: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    clearMessages = () => {
        this.successMessage = ''
        this.errorMessage = ''
        this.warningMessage = ''
        this.informationMessage = ''
    }
}
