import { ReactElement, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { responseStore, userStore } from '../../App'
import PaperGrid from '../StylingElements/PaperGrid'
import { ADD_COLLECTION } from '../../api/apiHooks/collection/useAddCollection'
import EditCollectionForm from './EditCollection.form'
import { RoutesList } from '../../routes/routes'

function AddCollection(): ReactElement {
    const { isLoggedIn } = userStore
    const navigate = useNavigate()
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            title: '',
            description: '',
            public: false,
        },
    })
    const [addCollectionCallback, { data }] = useMutation(ADD_COLLECTION)

    useEffect(() => {
        if (data?.addCollection?.data) {
            responseStore.successMessage = data.addCollection?.result
            responseStore.open = true
            navigate(
                `${RoutesList.PublicCollections}/${data?.addCollection?.data.id}`
            )
        }
        if (data?.addCollection?.error) {
            responseStore.errorMessage = data.addCollection.error
            responseStore.open = true
        }
    }, [data])

    const onSubmit = (submitData: any) => {
        const submitValues = {
            title: submitData.title,
            description: submitData.description,
            public: Boolean(submitData.public),
        }

        reset(submitValues)
        if (addCollectionCallback) {
            addCollectionCallback({
                variables: submitValues,
            }).then()
        }
    }

    return (
        <>
            {isLoggedIn && (
                <>
                    <form>
                        <PaperGrid>
                            <EditCollectionForm
                                control={control}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                headline="Collection hinzufügen"
                            />
                        </PaperGrid>
                    </form>
                </>
            )}
        </>
    )
}

export default observer(AddCollection)
