import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Picture } from '../../types'
import { userStore } from '../../../App'

export const PICTURES = gql`
    query getPictureById($id: Int!) {
        getPictureById(id: $id) {
            id
            title
            source
            place
            event
            description
            created_at
            shot_at
            updated_at
            created_by_user_id
            updated_by_user_id
            collections {
                id
                title
                description
                created_at
                updated_at
                created_by_user_id
                updated_by_user_id
            }
            photographer {
                id
                firstname
                lastname
            }
        }
    }
`

export default function useGetPictureById(
    id: string
): [Picture | null, Dispatch<SetStateAction<Picture | null>>, boolean] {
    const { isLoggedIn } = userStore
    const [picture, setPicture] = useState<Picture | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [graphqlCallback] = useLazyQuery(PICTURES)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback({
                variables: { id: Number(id) },
            }).then((res) => {
                if (res?.data?.getPictureById) {
                    setPicture({
                        id: res.data.getPictureById.id,
                        title: res.data.getPictureById.title || '',
                        source: res.data.getPictureById.source,
                        place: res.data.getPictureById.place || '',
                        event: res.data.getPictureById.event || '',
                        description: res.data.getPictureById.description || '',
                        shot_at: res.data.getPictureById.shot_at || '',
                        created_at: res.data.getPictureById.created_at,
                        updated_at: res.data.getPictureById.updated_at,
                        updated_by_user_id:
                            res.data.getPictureById.updated_by_user_id,
                        created_by_user_id:
                            res.data.getPictureById.created_by_user_id,
                        collections: res.data.getPictureById?.collections,
                        photographer: res.data.getPictureById.photographer,
                    })
                    setIsLoading(false)
                }
            })
        }
        return () => {
            setPicture(null)
        }
    }, [isLoggedIn])

    return [picture, setPicture, isLoading]
}
