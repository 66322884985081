import { gql, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesList } from '../../../routes/routes'
import { User } from '../../types'
import { UseLazyQueryType } from '../types'
import { userStore } from '../../../App'

const LOGIN = gql`
    query Login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            result
            error
            data {
                id
                username
                email
                active
                darkmode
                token
            }
        }
    }
`

export default function useLogin(): [UseLazyQueryType] {
    const [loginCallback, { data }] = useLazyQuery(LOGIN)
    const navigate = useNavigate()

    const { login } = userStore

    useEffect(() => {
        if (data && data.login && data.login.data && data.login.data.token) {
            const user: User = {
                id: data.login.data.id,
                username: data.login.data.username,
                email: data.login.data.email,
                token: data.login.data.token,
                active: data.login.data.active,
                darkmode: data.login.data.darkmode,
            }

            login(user)

            navigate(RoutesList.Home)
        }
    }, [data])
    return [loginCallback]
}
