import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { userStore } from '../../App'
import PaperFlex from '../StylingElements/PaperFlex'
import EditMyCollection from './EditMyCollection'
import useGetCollectionById from '../../api/apiHooks/collection/useGetCollectionById'
import getPicturesFromCollection from '../../api/apiHooks/picture/useGetPicturesFromCollection'
import GalleryWithPagination from '../StylingElements/GalleryWithPagination'

function CollectionEntry(): ReactElement | null {
    const { isLoggedIn } = userStore
    const { id } = useParams()

    if (!id) return null

    const [collection, , isMyCollection] = useGetCollectionById(id)
    const [pictures] = getPicturesFromCollection(id)

    return (
        <>
            {isLoggedIn &&
                collection &&
                pictures &&
                isMyCollection !== undefined && (
                    <>
                        <PaperFlex>
                            {!isMyCollection && (
                                <>
                                    <Typography
                                        sx={{ mt: 0, mb: 2 }}
                                        variant="h6"
                                        component="div"
                                    >
                                        {collection.title}
                                    </Typography>
                                    <Typography
                                        sx={{ mt: 0, mb: 2 }}
                                        variant="subtitle1"
                                        component="div"
                                    >
                                        {collection.description}
                                    </Typography>
                                </>
                            )}
                            {isMyCollection && collection && (
                                <EditMyCollection collection={collection} />
                            )}
                        </PaperFlex>
                        {pictures && (
                            <PaperFlex>
                                <GalleryWithPagination pictures={pictures} />
                            </PaperFlex>
                        )}
                    </>
                )}
        </>
    )
}

export default observer(CollectionEntry)
