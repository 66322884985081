import React from 'react'
import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import { EditImageDetails, Login, Pictures, SearchExtended } from './Components'
import { RoutesList } from './routes/routes'
import PublicCollections from './Components/Collection/PublicCollections'
import CollectionEntry from './Components/Collection/CollectionEntry'
import AddCollection from './Components/Collection/AddCollection'
import { UserStore } from './stores/userStore'
import { ResponseStore } from './stores/responseStore'
import MyCollections from './Components/Collection/MyCollections'
import UserProfile from './Components/user/UserProfile'

export const userStore = new UserStore()
export const responseStore = new ResponseStore()

function App() {
    return (
        <Routes>
            <Route path={RoutesList.Home} element={<Pictures />} />
            <Route
                path={`${RoutesList.EditImageDetails}/`}
                element={<Navigate to="/" replace />}
            />
            <Route
                path={`${RoutesList.EditImageDetails}/:id`}
                element={<EditImageDetails />}
            />
            <Route path={RoutesList.Login} element={<Login />} />
            <Route
                path={RoutesList.SearchExtended}
                element={<SearchExtended />}
            />
            <Route
                path={RoutesList.PublicCollections}
                element={<PublicCollections />}
            />
            <Route
                path={`${RoutesList.PublicCollections}/:id`}
                element={<CollectionEntry />}
            />
            <Route
                path={RoutesList.AddCollection}
                element={<AddCollection />}
            />
            <Route
                path={RoutesList.MyCollections}
                element={<MyCollections />}
            />
            <Route path={RoutesList.UserProfile} element={<UserProfile />} />
        </Routes>
    )
}

export default App
