import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Collection } from '../../types'
import { userStore } from '../../../App'

export const GET_COLLECTION_BY_ID = gql`
    query getCollectionById($id: Int!) {
        getCollectionById(id: $id) {
            id
            title
            description
            created_at
            updated_at
            created_by_user_id
            updated_by_user_id
            public
        }
    }
`

export default function useGetCollectionById(
    id: string
): [
    Collection | null,
    Dispatch<SetStateAction<Collection | null>>,
    Boolean | undefined
] {
    const { isLoggedIn, user } = userStore
    const [collection, setCollection] = useState<Collection | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_COLLECTION_BY_ID)
    const [isMyCollection, setIsMyCollection] = useState<boolean>()

    useEffect(() => {
        if (collection && collection.created_by_user_id && user) {
            setIsMyCollection(collection.created_by_user_id === user.id)
        }
    }, [collection, user])

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback({
                variables: { id: Number(id) },
            }).then((res) => {
                if (res?.data?.getCollectionById) {
                    setCollection({
                        ...res.data.getCollectionById,
                        title: res.data.getCollectionById.title || '',
                        description:
                            res.data.getCollectionById.description || '',
                    })
                }
            })
        }
        return () => {
            setCollection(null)
        }
    }, [isLoggedIn])

    return [collection, setCollection, isMyCollection]
}
