import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Person } from '../../types'
import { userStore } from '../../../App'

const GET_PERSONS_FROM_PICTURE = gql`
    query getPersonsFromPicture($id: Int!) {
        getPersonsFromPicture(id: $id) {
            id
            firstname
            lastname
        }
    }
`

export default function useGetPersonsFromPicture(
    id: number
): [Person[] | null, Dispatch<SetStateAction<Person[] | null>>] {
    const { isLoggedIn } = userStore
    const [persons, setPersons] = useState<Person[] | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_PERSONS_FROM_PICTURE)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback({
                variables: { id: Number(id) },
            }).then((res) => {
                if (res?.data?.getPersonsFromPicture) {
                    setPersons(res.data.getPersonsFromPicture)
                }
            })
        }
        return () => {
            setPersons(null)
        }
    }, [isLoggedIn])

    return [persons, setPersons]
}
