import React, { ReactElement, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { userStore } from '../../App'
import FormInputText from '../Form/FormInputText'
import FormRadioGroup from '../Form/FormRadioGroup'
import PaperFlex from '../StylingElements/PaperFlex'
import useUpdateUser from '../../api/apiHooks/user/useUpdateUser'
import { User } from '../../api/types'

function UserProfile(): ReactElement | null {
    const { isLoggedIn, user } = userStore

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            id: 0,
            username: '',
            password: '',
            email: '',
            darkmode: false,
            active: false,
        },
    })

    useMemo(() => {
        if (user) {
            reset(user)
        }
    }, [user])

    const onSubmit = (data: any) => {
        const formValues: User = {
            ...data,
            darkmode: data.darkmode === 'true',
        }
        if (isLoggedIn && formValues && user) {
            useUpdateUser(isLoggedIn, formValues, user)
        }
    }

    return (
        <>
            {isLoggedIn && user && (
                <PaperFlex>
                    <Typography
                        sx={{ mt: 0, mb: 2 }}
                        variant="h6"
                        component="div"
                    >
                        Profil
                    </Typography>
                    <FormInputText
                        name="username"
                        control={control}
                        label="Username"
                    />
                    <FormInputText
                        name="password"
                        control={control}
                        label="Password"
                    />
                    <FormInputText
                        name="email"
                        control={control}
                        label="Email"
                    />
                    <FormRadioGroup
                        name="darkmode"
                        label="Darkmode"
                        control={control}
                    />
                    <Button onClick={handleSubmit(onSubmit)} type="submit">
                        Submit
                    </Button>
                </PaperFlex>
            )}
        </>
    )
}

export default observer(UserProfile)
