import { ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { observer } from 'mobx-react-lite'
import FormInputText from '../Form/FormInputText'
import FormInputDate from '../Form/FormInputDate'
import { UpdatePicture } from '../../api/updatePicture'
import ManagePersons from './ManagePersons'
import { AutocompleteOption } from '../types'
import FormAutocomplete from '../Form/FormAutocomplete'
import { userStore } from '../../App'
import PaperGrid from '../StylingElements/PaperGrid'
import Image from '../StylingElements/Image'
import useGetPictureById from '../../api/apiHooks/picture/useGetPictureById'
import FormAutocompleteMultipleValues from '../Form/FormAutocompleteMultipleValues'
import useGetCollectionsFromPicture from '../../api/apiHooks/collection/useGetCollectionsFromPicture'
import useRemoveCollectionPicture from '../../api/apiHooks/collectionPicture/useRemoveCollectionPicture'
import useAddCollectionPicture from '../../api/apiHooks/collection/useAddCollectionPicture'
import {
    mapCollectionToAutocompleteOption,
    mapPersonToAutocompleteOption,
} from '../Form/helpers'
import { setErrorMessage, setSuccessMessage } from '../../api/apiHooks/helpers'
import useGetPersons from '../../api/apiHooks/person/useGetPersons'
import useGetCollections from '../../api/apiHooks/collection/useGetCollections'

function EditImageDetails(): ReactElement | null {
    const { isLoggedIn } = userStore
    const { id } = useParams()
    if (!id) {
        return null
    }
    const [autocompleteOptions, setAutocompleteOptions] = useState<
        AutocompleteOption[]
    >([])
    const [
        photographerAutocompleteOptions,
        setPhotographerAutocompleteOptions,
    ] = useState<AutocompleteOption[]>([])

    const { handleSubmit, reset, control } = useForm()
    const [picture, , isLoading] = useGetPictureById(id)
    const [collectionsFromPicture] = useGetCollectionsFromPicture(id)
    const [persons] = useGetPersons()
    const [collections] = useGetCollections()

    const [updateImage] = useMutation(UpdatePicture())

    useEffect(() => {
        if (picture) {
            reset({
                id: picture.id,
                title: picture.title,
                source: picture.source,
                place: picture.place,
                event: picture.event,
                description: picture.description,
                shot_at: picture.shot_at,
                photographer: picture.photographer,
            })
        }
    }, [picture])

    useEffect(() => {
        if (collectionsFromPicture) {
            reset({ collections: collectionsFromPicture })
        }
    }, [collectionsFromPicture])

    useEffect(() => {
        if (persons) {
            setPhotographerAutocompleteOptions(
                persons.map(mapPersonToAutocompleteOption)
            )
        }
    }, [persons])

    useEffect(() => {
        if (collections) {
            setAutocompleteOptions(
                collections.map(mapCollectionToAutocompleteOption)
            )
        }
    }, [collections])

    const onSubmit = (data: any) => {
        const updatedValues = {
            id: picture?.id,
            title: data.title,
            place: data.place,
            event: data.event,
            description: data.description,
            shot_at: data.shot_at,
            photographer: data.photographer ? data.photographer.id : null,
        }
        const resetValues = {
            title: data.title,
            place: data.place,
            event: data.event,
            description: data.description,
            shot_at: data.shot_at,
            photographer: data.photographer,
        }
        reset(resetValues)
        updateImage({
            variables: updatedValues,
        }).then((res) => {
            if (!res?.errors) {
                setSuccessMessage('Updated successfully')
            }

            if (res?.errors) {
                setErrorMessage(res.errors[0].message)
            }
        })
    }

    function onRemoveOptionCallback(option: AutocompleteOption): void {
        if (id) useRemoveCollectionPicture(isLoggedIn, id, option.id)
    }

    function onAddOptionCallback(option: AutocompleteOption): void {
        if (id) useAddCollectionPicture(isLoggedIn, id, option.id)
    }
    return (
        <>
            {isLoggedIn && !isLoading && picture && (
                <form>
                    <PaperGrid>
                        <Paper
                            variant="outlined"
                            style={{
                                margin: 'auto',
                            }}
                        >
                            <Image
                                src={picture.source}
                                srcSet={picture.source}
                                alt={picture.title}
                            />
                        </Paper>
                        <FormInputText
                            name="id"
                            control={control}
                            label="Id"
                            disabled
                        />
                        <FormInputText
                            name="title"
                            control={control}
                            label="Titel"
                        />
                        <FormInputText
                            name="event"
                            control={control}
                            label="Event"
                        />
                        <FormInputText
                            name="place"
                            control={control}
                            label="Ort"
                        />
                        <FormInputDate
                            name="shot_at"
                            control={control}
                            label="Datum"
                        />
                        <FormAutocomplete
                            name="photographer"
                            control={control}
                            label="Fotograf"
                            autocompleteOptions={
                                photographerAutocompleteOptions
                            }
                            defaultValue={picture.photographer}
                        />
                        <FormAutocompleteMultipleValues
                            id="collection-autocomplete"
                            label="Collections"
                            autocompleteOptions={autocompleteOptions}
                            defaultValue={picture?.collections.map(
                                mapCollectionToAutocompleteOption
                            )}
                            onRemoveOptionCallback={onRemoveOptionCallback}
                            onAddOptionCallback={onAddOptionCallback}
                            disableClearable
                        />
                        <ManagePersons pictureId={Number(id)} />
                        <FormInputText
                            name="description"
                            control={control}
                            label="Beschreibung"
                            multiline
                            rows={5}
                        />
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            Submit
                        </Button>
                    </PaperGrid>
                </form>
            )}
        </>
    )
}

export default observer(EditImageDetails)
