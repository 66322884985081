import React, { ReactElement, useEffect, useState } from 'react'
import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import { createStyles, makeStyles } from '@mui/styles'
import { observer } from 'mobx-react-lite'
import { Header } from '../Components'
import { apolloClient } from '../api/apolloClient'
import Responsebar from '../Components/Responsebar/Responsebar'
import { userStore } from '../App'

type LayoutProps = {
    children: React.ReactNode
}

function Layout({ children }: LayoutProps): ReactElement {
    const { isLoggedIn, user } = userStore
    const [darkMode, setDarkMode] = useState<boolean>(true)

    useEffect(() => {
        if (isLoggedIn && user) {
            setDarkMode(user.darkmode)
        }
    }, [isLoggedIn, user])

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {},
        })
    )
    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            background: {
                default: darkMode ? '#121212' : '#FFF',
            },
        },
    })
    const classes = useStyles()

    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />
                    <Header darkMode={darkMode} setDarkMode={setDarkMode} />
                    <Responsebar />
                    {children}
                </div>
            </ThemeProvider>
        </ApolloProvider>
    )
}

export default observer(Layout)
