import { CSSProperties, ReactElement, ReactNode } from 'react'
import { Paper } from '@mui/material'
import useIsMobile from '../../helper/hooks/useIsMobile'

type PaperProps = {
    children: ReactNode
}

export default function PaperFlex({ children }: PaperProps): ReactElement {
    const isMobile = useIsMobile()
    const mobileStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '20px',
        padding: '20px',
        width: '100%',
    }
    const desktopStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '20px',
        padding: '20px',
        margin: '20px auto auto auto',
        width: '80%',
    }

    return (
        <Paper style={isMobile ? mobileStyle : desktopStyle}>{children}</Paper>
    )
}
