import { makeAutoObservable, reaction } from 'mobx'
import { User } from '../api/types'
import { LOGIN_WITH_TOKEN } from '../api/apiHooks/auth/useLoginWithToken'
import { apolloClient } from '../api/apolloClient'

export class UserStore {
    user: User | null = null

    isLoggedIn: boolean = false

    constructor() {
        makeAutoObservable(this)
        reaction(
            () => this.user,
            (user) => {
                if (user) {
                    this.isLoggedIn = true
                } else {
                    this.isLoggedIn = false
                    localStorage.removeItem('token')
                }
            }
        )
        this.init()
    }

    init = () => {
        const token = localStorage.getItem('token')
        if (token && !this.isLoggedIn) {
            apolloClient
                .query({
                    query: LOGIN_WITH_TOKEN,
                    variables: {
                        token,
                    },
                })
                .then((res) => {
                    if (res?.data?.login?.data) {
                        this.user = res.data.login.data
                    }
                })
        }
    }

    get userData(): User | null {
        return this.user
    }

    updateUserData = (user: User) => {
        this.user = user
    }

    toggleDarkmode = () => {
        if (this.user) {
            this.user.darkmode = !!this.user?.darkmode
        }
    }

    logout = () => {
        this.user = null
        localStorage.removeItem('token')
    }

    login = (user: User) => {
        if (user) {
            this.user = user
            localStorage.setItem('token', user.token)
        }
    }
}
