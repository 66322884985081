import { responseStore } from '../../App'

export function setErrorMessage(error: string): void {
    responseStore.open = false
    responseStore.errorMessage = error
    responseStore.open = true
}

export function setSuccessMessage(success: string): void {
    responseStore.open = false
    responseStore.successMessage = success
    responseStore.open = true
}
