import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Person } from '../../types'
import { userStore } from '../../../App'

const GET_PERSONS = gql`
    query allPersons {
        allPersons {
            id
            firstname
            lastname
        }
    }
`

export default function useGetPersons(): [
    Person[] | null,
    Dispatch<SetStateAction<Person[] | null>>
] {
    const { isLoggedIn } = userStore
    const [persons, setPersons] = useState<Person[] | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_PERSONS)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback().then((res) => {
                if (res?.data?.allPersons) {
                    setPersons(res.data.allPersons)
                }
            })
        }
        return () => {
            setPersons(null)
        }
    }, [isLoggedIn])

    return [persons, setPersons]
}
