import { ReactElement, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import { AutocompleteOption } from '../types'
import { Persons, Picture } from '../../api/types'
import { mapOptionToPerson } from '../../helper/personHelpers'
import { userStore } from '../../App'
import PaperFlex from '../StylingElements/PaperFlex'
import { mapPersonToAutocompleteOption } from '../Form/helpers'
import useGetJointPicturesOfPersons from '../../api/apiHooks/picture/useGetJointPicturesOfPersons'
import FormAutocompleteMultipleValues from '../Form/FormAutocompleteMultipleValues'
import useGetPersons from '../../api/apiHooks/person/useGetPersons'
import sortPersons from '../../helper/sortHelpers'
import GalleryWithPagination from '../StylingElements/GalleryWithPagination'

function SearchExtended(): ReactElement {
    const { isLoggedIn } = userStore
    const [persons, setPersons] = useGetPersons()
    const [personsToFilter, setPersonsToFilter] = useState<Persons>([])
    const [pictures, setPictures] = useState<Picture[] | null>(null)

    useEffect(() => {
        if (personsToFilter.length === 0) {
            setPictures([])
        }
        if (personsToFilter.length > 0) {
            useGetJointPicturesOfPersons(personsToFilter).then((res) => {
                if (res?.data?.getJointPicturesOfPersons) {
                    setPictures(res.data.getJointPicturesOfPersons)
                }
            })
        }
    }, [personsToFilter])

    function onRemoveOptionCallback(option: AutocompleteOption): void {
        const mappedPerson = mapOptionToPerson(option)
        setPersonsToFilter(
            // eslint-disable-next-line camelcase
            personsToFilter.filter((element) => element.id !== mappedPerson.id)
        )
        if (persons) {
            setPersons(
                [...persons, mapOptionToPerson(option)].sort(sortPersons)
            )
        }
    }

    function onAddOptionCallback(option: AutocompleteOption): void {
        setPersonsToFilter([...personsToFilter, mapOptionToPerson(option)])
        setPersons(
            persons && persons.filter((person) => person.id !== option.id)
        )
    }

    const headline = 'Filtern nach Personen'

    return (
        <>
            {isLoggedIn && persons && (
                <>
                    <PaperFlex>
                        <Typography
                            sx={{ mt: 0, mb: 2 }}
                            variant="h6"
                            component="div"
                        >
                            {headline}
                        </Typography>
                        <FormAutocompleteMultipleValues
                            id="persons-autocomplete"
                            label="Personen"
                            autocompleteOptions={persons.map(
                                mapPersonToAutocompleteOption
                            )}
                            onRemoveOptionCallback={onRemoveOptionCallback}
                            onAddOptionCallback={onAddOptionCallback}
                            disableClearable
                        />
                    </PaperFlex>
                    <PaperFlex>
                        {!pictures && (
                            <Typography
                                sx={{ mt: 0, mb: 2 }}
                                variant="h6"
                                component="div"
                            >
                                Keine Suchergebnisse
                            </Typography>
                        )}
                        {pictures && pictures.length === 0 && (
                            <Typography
                                sx={{ mt: 0, mb: 2 }}
                                variant="h6"
                                component="div"
                            >
                                Keine Suchergebnisse
                            </Typography>
                        )}
                        {pictures && pictures.length > 0 && (
                            <GalleryWithPagination pictures={pictures} />
                        )}
                    </PaperFlex>
                </>
            )}
        </>
    )
}

export default observer(SearchExtended)
