import { Person } from '../api/types'

export default function sortPersons(n1: Person, n2: Person) {
    if (n1.firstname > n2.firstname) {
        return 1
    }

    if (n1.firstname < n2.firstname) {
        return -1
    }

    return 0
}
