import { ReactElement, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Collection } from '../../api/types'
import { UpdateCollection } from '../../api/updateCollection'
import { setErrorMessage, setSuccessMessage } from '../../api/apiHooks/helpers'
import EditCollectionForm from './EditCollection.form'
import useRemoveCollection from '../../api/apiHooks/collection/useRemoveCollection'
import { userStore } from '../../App'
import { RoutesList } from '../../routes/routes'

type EditMyCollectionProps = { collection: Collection }

function EditMyCollection({
    collection,
}: EditMyCollectionProps): ReactElement | null {
    const { isLoggedIn } = userStore
    const navigate = useNavigate()

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            title: '',
            description: '',
            public: false,
        },
    })
    useMemo(() => {
        if (collection) {
            reset(collection)
        }
    }, [collection])

    const [updateCollectionCallback] = useMutation(UpdateCollection())

    const onSubmit = (data: any) => {
        const updatedCollection = {
            ...data,
            public: data.public === 'true' || data.public === true,
        }
        updateCollectionCallback({ variables: updatedCollection }).then(
            (res) => {
                if (!res?.errors && !res?.data?.addCollectionPicture?.error) {
                    setSuccessMessage('Collection updated successfully')
                }

                if (res?.errors) {
                    setErrorMessage(res.errors[0].message)
                }

                if (res?.data?.addCollectionPicture?.error) {
                    setErrorMessage(res.data.addCollectionPicture.error)
                }
            }
        )
    }

    const onRemove = (removeData: any) => {
        useRemoveCollection(isLoggedIn, removeData.id)
        navigate(RoutesList.Home)
    }

    return (
        <>
            <EditCollectionForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onRemove={onRemove}
                headline="Collection bearbeiten"
            />
        </>
    )
}

export default observer(EditMyCollection)
