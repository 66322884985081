import { ReactElement, ReactNode, useState } from 'react'
import { css, Modal, styled } from '@mui/material'
import Fade from '@mui/material/Fade'
import * as React from 'react'

export type Rotation = 'left' | 'right'

interface WrapperStyledProps {
    fullscreen: boolean
}

const ArrowLeft = styled('div')`
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;

    svg {
        width: 50px;
        height: 50px;
    }
`

const ArrowRight = styled('div')`
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;

    svg {
        width: 50px;
        height: 50px;
    }
`

const FullscreenButton = styled('div')`
    display: flex;
    background-color: transparent;
    fill: white;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
    bottom: 50px;
    z-index: 999;

    svg {
        width: 50px;
        height: 50px;
    }
`

const Wrapper = styled('div')<WrapperStyledProps>(
    ({ fullscreen }) =>
        css`
            height: 100%;
            padding: ${fullscreen ? '0' : '50px 0'};
            display: flex;
            background: rgba(0, 0, 0, 0.9);
            cursor: pointer;
            img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                object-fit: contain;
                margin: auto;
                cursor: default;
            }

            //no-text-selection
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        `
)

const CloseButton = styled('button')`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
    background-color: transparent;
    border: none;
`

type TransitionModalProps = {
    open: boolean
    handleClose: () => void
    handelRotation: (rotation: Rotation) => void
    children: ReactNode
    isMobile: boolean
}

// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
export enum KeyboardKeys {
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
}

const FullscreenMaximizeIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        className="bi bi-arrows-fullscreen"
        viewBox="0 0 16 16"
    >
        <path
            fillRule="evenodd"
            d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"
        />
    </svg>
)

const FullscreenMinimizeIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-fullscreen-exit"
        viewBox="0 0 16 16"
    >
        <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z" />
    </svg>
)

const ArrowLeftIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
        />
    </svg>
)
const ArrowRightIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
        />
    </svg>
)

export function TransitionImageModal({
    open,
    handleClose,
    children,
    handelRotation,
    isMobile,
}: TransitionModalProps): ReactElement {
    const [fullscreen, setFullscreen] = useState(isMobile)
    const toggleFullscreen = () => {
        setFullscreen(!fullscreen)
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} timeout={350}>
                <Wrapper
                    onClick={(event) => {
                        event.preventDefault()
                        if (event.target === event.currentTarget) {
                            handleClose()
                        }
                    }}
                    fullscreen={fullscreen}
                    onKeyDown={(e) => {
                        if (e.key === KeyboardKeys.ARROW_LEFT) {
                            handelRotation('left')
                        } else if (e.key === KeyboardKeys.ARROW_RIGHT) {
                            handelRotation('right')
                        }
                    }}
                >
                    <>
                        <CloseButton onClick={handleClose}>X</CloseButton>
                        <ArrowLeft onClick={() => handelRotation('left')}>
                            {ArrowLeftIcon}
                        </ArrowLeft>
                        <ArrowRight onClick={() => handelRotation('right')}>
                            {ArrowRightIcon}
                        </ArrowRight>
                        {!isMobile && (
                            <FullscreenButton onClick={toggleFullscreen}>
                                {fullscreen
                                    ? FullscreenMinimizeIcon
                                    : FullscreenMaximizeIcon}
                            </FullscreenButton>
                        )}
                        {children}
                    </>
                </Wrapper>
            </Fade>
        </Modal>
    )
}
