import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Picture } from '../../types'
import { userStore } from '../../../App'

export const PICTURES = gql`
    query getPictures {
        getPictures {
            id
            title
            source
            place
            event
            description
            shot_at
        }
    }
`

export default function useGetPictures(): [
    Picture[] | null,
    Dispatch<SetStateAction<Picture[] | null>>
] {
    const { isLoggedIn } = userStore
    const [pictures, setPictures] = useState<Picture[] | null>(null)
    const [graphqlCallback] = useLazyQuery(PICTURES)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback().then((res) => {
                if (res?.data?.getPictures) {
                    setPictures(res.data.getPictures)
                }
            })
        }
        return () => {
            setPictures(null)
        }
    }, [isLoggedIn])

    return [pictures, setPictures]
}
