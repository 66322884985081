import { ReactElement, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormAutocompleteProps } from './FormInputProps'
import { AutocompleteOption } from '../types'

export default function FormAutocomplete({
    name,
    control,
    label,
    autocompleteOptions,
    defaultValue,
    disableClearable = false,
}: FormAutocompleteProps): ReactElement {
    const [value, setValue] = useState<AutocompleteOption | null>({
        label: '',
        value: '',
        id: 0,
    })

    useEffect(() => {
        const defaultOption = autocompleteOptions.find(
            (option) => option.id === Number(defaultValue?.id)
        )
        if (defaultOption) {
            setValue(defaultOption)
        }
    }, [defaultValue, autocompleteOptions])

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => (
                <Autocomplete
                    disablePortal
                    disableClearable={disableClearable}
                    id="collection-autocomplete"
                    options={autocompleteOptions}
                    getOptionLabel={(option) => option.label || ''}
                    value={value}
                    onChange={(_, data) => {
                        onChange(data)
                        setValue(data)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={label} />
                    )}
                />
            )}
        />
    )
}
