import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import useGetPictures from '../api/apiHooks/picture/useGetPictures'
import GalleryWithPagination from './StylingElements/GalleryWithPagination'

function Pictures(): ReactElement | null {
    const [pictures] = useGetPictures()

    return <>{pictures && <GalleryWithPagination pictures={pictures} />}</>
}

export default observer(Pictures)
