import { gql } from '@apollo/client'

export function UpdateCollection(): any {
    return gql`
        mutation updateCollection(
            $id: Int!
            $title: String
            $description: String
            $public: Boolean!
        ) {
            updateCollection(
                id: $id
                title: $title
                description: $description
                public: $public
            ) {
                id
                title
                description
                public
            }
        }
    `
}
