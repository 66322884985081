import { gql } from '@apollo/client'

export function removePersonFromPicture(): any {
    return gql`
        mutation removePersonFromPicture($person_id: Int!, $picture_id: Int!) {
            removePersonFromPicture(
                person_id: $person_id
                picture_id: $picture_id
            ) {
                error
                result
            }
        }
    `
}
