import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { ReactElement } from 'react'
import { FormAutocompleteMultipleValuesProps } from './FormInputProps'

export default function FormAutocompleteMultipleValues({
    id,
    label,
    autocompleteOptions,
    defaultValue,
    onRemoveOptionCallback,
    onAddOptionCallback,
    disableClearable = false,
}: FormAutocompleteMultipleValuesProps): ReactElement {
    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Autocomplete
                multiple
                id={id}
                options={autocompleteOptions}
                getOptionLabel={(option) => option.label}
                defaultValue={defaultValue}
                filterSelectedOptions
                disableClearable={disableClearable}
                onChange={(_, data, reason, details) => {
                    if (reason === 'removeOption' && details?.option) {
                        onRemoveOptionCallback(details.option)
                    }

                    if (reason === 'selectOption' && details?.option) {
                        onAddOptionCallback(details.option)
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} label={label} />
                )}
            />
        </Stack>
    )
}
