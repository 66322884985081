import { gql } from '@apollo/client'

export const addPersonToPicture = gql`
    mutation addPersonToPicture($picture_id: Int!, $person_id: Int!) {
        addPersonToPicture(picture_id: $picture_id, person_id: $person_id) {
            result
            error
        }
    }
`
