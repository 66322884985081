// eslint-disable-next-line no-shadow
export enum RoutesList {
    Home = '/',
    EditImageDetails = '/edit-images',
    Login = '/login',
    SearchExtended = '/search-extended',
    PublicCollections = '/collections',
    AddCollection = '/add-collection',
    MyCollections = '/my-collections',
    UserProfile = '/profile',
}
