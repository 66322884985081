import { CSSProperties, ReactElement } from 'react'
import useIsMobile from '../../helper/hooks/useIsMobile'

type PaperProps = {
    src: string
    srcSet: string
    alt: string
}

export default function Image({ src, srcSet, alt }: PaperProps): ReactElement {
    const isMobile = useIsMobile()
    const mobileStyle: CSSProperties = {
        maxWidth: '100%',
        height: 'auto',
    }
    const desktopStyle: CSSProperties = {
        maxWidth: '960px',
        maxHeight: '500px',
        height: 'auto',
    }

    return (
        <img
            src={src}
            srcSet={srcSet}
            alt={alt}
            loading="lazy"
            style={isMobile ? mobileStyle : desktopStyle}
        />
    )
}
