import { ReactElement } from 'react'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormInputTextProps } from './FormInputProps'

export default function FormRadioGroup({
    name,
    label,
    control,
}: FormInputTextProps): ReactElement {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <RadioGroup aria-label="public" {...field}>
                    <FormLabel component="legend">{label}</FormLabel>
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Ja"
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Nein"
                    />
                </RadioGroup>
            )}
        />
    )
}
