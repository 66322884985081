import { ReactElement } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import PhotoIcon from '@mui/icons-material/Photo'
import { useNavigate } from 'react-router-dom'
import { Collection } from '../../api/types'
import { RoutesList } from '../../routes/routes'
import { userStore } from '../../App'
import PaperFlex from '../StylingElements/PaperFlex'

type CollectionListItemProps = {
    collection: Collection
}

export default function CollectionListItem({
    collection,
}: CollectionListItemProps): ReactElement {
    const { isLoggedIn } = userStore
    const navigate = useNavigate()
    if (!isLoggedIn) {
        navigate(RoutesList.Login)
    }

    return (
        <PaperFlex>
            <Grid item xs={12} md={6}>
                <List>
                    <ListItem
                        onClick={() =>
                            navigate(
                                `${RoutesList.PublicCollections}/${collection.id}`
                            )
                        }
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <PhotoIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={collection.title}
                            secondary={collection.description}
                        />
                    </ListItem>
                </List>
            </Grid>
        </PaperFlex>
    )
}
