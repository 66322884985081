import * as React from 'react'
import { ChangeEvent, ReactElement, useState } from 'react'
import {
    Box,
    css,
    FormControl,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    styled,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import usePagination from '../../helper/hooks/usePagniation'
import { Picture, Pictures } from '../../api/types'
import useIsMobile from '../../helper/hooks/useIsMobile'
import useIsTablet from '../../helper/hooks/useIsTablet'
import { RoutesList } from '../../routes/routes'
import { Rotation, TransitionImageModal } from './TransitionImageModal'

type PaginationProps = { pictures: Pictures }

export default function GalleryWithPagination({
    pictures,
}: PaginationProps): ReactElement {
    const navigate = useNavigate()
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    function getCols(): number {
        if (isMobile) return 2
        if (isTablet) return 3
        return 4
    }

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(getCols() * 8)

    const count = Math.ceil(pictures.length / perPage)
    const _DATA = usePagination(pictures, perPage)

    const handlePerPageChange = (event: SelectChangeEvent) => {
        if (Number(event.target.value) === pictures.length) {
            setPerPage(pictures.length)
            setPage(1)
            _DATA.jump(1)
        } else {
            setPerPage(Number(event.target.value))
        }

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }, 100)
    }

    const handleChange = (event: ChangeEvent<unknown>, pageToJump: number) => {
        setPage(pageToJump)
        _DATA.jump(pageToJump)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    interface WrapperStyledProps {
        ismobile: boolean
    }

    const ThumbnailContainer = styled('div')<WrapperStyledProps>(
        ({ ismobile }) =>
            css`
                display: flex;
                flex-direction: column;
                justify-content: center; /* Centering y-axis */
                align-items: center; /* Centering x-axis */
                cursor: pointer;
                img {
                    height: ${ismobile ? '300px' : 'auto'};
                    object-fit: ${ismobile ? 'cover' : 'contain'};
                    max-width: 100%;
                    max-height: 300px;
                }
            `
    )

    const [clickedImg, setClickedImg] = useState<Picture>()
    const [currentIndex, setCurrentIndex] = useState<number>()

    function handelRotation(rotation: Rotation): void {
        if (currentIndex === undefined) {
            return
        }
        if (rotation === 'left') {
            if (currentIndex === 0) {
                setCurrentIndex(perPage - 1)
                setClickedImg(pictures[perPage - 1])
                return
            }
        }
        if (rotation === 'right') {
            if (currentIndex + 1 >= perPage) {
                setCurrentIndex(0)
                const newPicture = pictures[0]
                setClickedImg(newPicture)
                return
            }
        }
        const newIndex =
            rotation === 'left' ? currentIndex - 1 : currentIndex + 1
        setClickedImg(
            pictures.filter((item) => {
                return pictures.indexOf(item) === newIndex
            })[0]
        )
        setCurrentIndex(newIndex)
    }

    const [showModal, setShowModal] = useState(false)
    const toggleModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <ImageList
                cols={getCols()}
                sx={{ overflow: 'hidden' }}
                rowHeight={300}
            >
                <TransitionImageModal
                    open={showModal}
                    handleClose={toggleModal}
                    handelRotation={handelRotation}
                    isMobile={isMobile}
                >
                    <img
                        src={clickedImg?.source}
                        srcSet={clickedImg?.source}
                        alt={clickedImg?.title}
                        loading="lazy"
                    />
                </TransitionImageModal>
                {_DATA.currentData().map((image, index) => {
                    return (
                        <ImageListItem
                            key={image.id}
                            sx={{ overflow: 'hidden' }}
                            style={{ justifyContent: 'center' }}
                        >
                            <ThumbnailContainer
                                onClick={() => {
                                    setCurrentIndex(index)
                                    setClickedImg(image)
                                    toggleModal()
                                }}
                                ismobile={isMobile}
                            >
                                <img
                                    src={image.source}
                                    srcSet={image.source}
                                    alt={image.title}
                                    loading="lazy"
                                />
                            </ThumbnailContainer>

                            <ImageListItemBar
                                title={image.title}
                                subtitle={image.description}
                                actionIcon={
                                    <IconButton
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.54)',
                                        }}
                                        aria-label={`info about ${image.title}`}
                                        onClick={() =>
                                            navigate(
                                                `${RoutesList.EditImageDetails}/${image.id}`
                                            )
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    )
                })}
            </ImageList>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ height: 32, minWidth: 120, paddingRight: 1 }}>
                    <FormControl fullWidth>
                        <Select
                            style={{ height: 32 }}
                            id="dropdown-per-page"
                            value={perPage.toString()}
                            onChange={handlePerPageChange}
                            displayEmpty
                        >
                            <MenuItem value={getCols() * 4}>
                                {getCols() * 4}
                            </MenuItem>
                            <MenuItem value={getCols() * 8}>
                                {getCols() * 8}
                            </MenuItem>
                            <MenuItem value={getCols() * 16}>
                                {getCols() * 16}
                            </MenuItem>
                            <MenuItem value={getCols() * 32}>
                                {getCols() * 32}
                            </MenuItem>
                            <MenuItem value={pictures.length}>Alle</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Pagination
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '16px',
                    }}
                    count={count}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                />
            </div>
        </>
    )
}
