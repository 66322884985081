import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Collection } from '../../types'
import { userStore } from '../../../App'

const GET_COLLECTIONS_FROM_USER = gql`
    query getUsersCollections {
        getUsersCollections {
            id
            title
            description
            created_at
            created_by_user_id
        }
    }
`

export default function useGetCollectionsFromUser(): [
    Collection[] | null,
    Dispatch<SetStateAction<Collection[] | null>>
] {
    const { isLoggedIn } = userStore
    const [collections, setCollections] = useState<Collection[] | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_COLLECTIONS_FROM_USER)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback().then((res) => {
                if (res?.data?.getUsersCollections) {
                    setCollections(res.data.getUsersCollections)
                }
            })
        }
        return () => {
            setCollections(null)
        }
    }, [isLoggedIn])

    return [collections, setCollections]
}
