import { gql } from '@apollo/client'
import { apolloClient } from '../../apolloClient'
import { setErrorMessage, setSuccessMessage } from '../helpers'

const REMOVE_COLLECTION = gql`
    mutation removeCollection($id: Int!) {
        removeCollection(id: $id) {
            id
            title
            description
            created_at
            updated_at
            created_by_user_id
            updated_by_user_id
            public
        }
    }
`

export default function useRemoveCollection(
    isLoggedIn: boolean,
    collectionId: string | number
): void {
    if (isLoggedIn && collectionId) {
        apolloClient
            .mutate({
                mutation: REMOVE_COLLECTION,
                variables: {
                    id: Number(collectionId),
                },
            })
            .then((res) => {
                if (!res?.errors) {
                    setSuccessMessage('Collection removed successfully')
                }

                if (res?.errors) {
                    setErrorMessage(res.errors[0].message)
                }
            })
    }
}
