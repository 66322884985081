import { gql } from '@apollo/client'
import { apolloClient } from '../../apolloClient'
import { setErrorMessage, setSuccessMessage } from '../helpers'

const ADD_COLLECTION_PICTURE = gql`
    mutation addCollectionPicture($picture_id: Int!, $collection_id: Int!) {
        addCollectionPicture(
            picture_id: $picture_id
            collection_id: $collection_id
        ) {
            error
            result
        }
    }
`

export default function useAddCollectionPicture(
    isLoggedIn: boolean,
    pictureId: string | number,
    collectionId: string | number
): void {
    if (isLoggedIn && pictureId && collectionId) {
        apolloClient
            .mutate({
                mutation: ADD_COLLECTION_PICTURE,
                variables: {
                    collection_id: Number(collectionId),
                    picture_id: Number(pictureId),
                },
            })
            .then((res) => {
                if (!res?.errors && !res?.data?.addCollectionPicture?.error) {
                    setSuccessMessage('Collection added successfully')
                }

                if (res?.errors) {
                    setErrorMessage(res.errors[0].message)
                }

                if (res?.data?.addCollectionPicture?.error) {
                    setErrorMessage(res.data.addCollectionPicture.error)
                }
            })
    }
}
