import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Collection } from '../../types'
import { userStore } from '../../../App'

export const PICTURES = gql`
    query getCollectionsFromPicture($id: Int!) {
        getCollectionsFromPicture(id: $id) {
            id
            title
            description
            created_at
            updated_at
            created_by_user_id
            updated_by_user_id
            public
        }
    }
`

export default function useGetCollectionsFromPicture(
    id: string
): [Collection[] | null, Dispatch<SetStateAction<Collection[] | null>>] {
    const { isLoggedIn } = userStore
    const [collections, setCollections] = useState<Collection[] | null>(null)
    const [graphqlCallback] = useLazyQuery(PICTURES)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback({ variables: { id: Number(id) } }).then((res) => {
                if (res?.data?.getCollectionsFromPicture) {
                    setCollections(res.data.getCollectionsFromPicture)
                }
            })
        }
        return () => {
            setCollections(null)
        }
    }, [isLoggedIn])

    return [collections, setCollections]
}
