import { useState } from 'react'

export default function usePagination(
    data: Array<any>,
    itemsPerPage: number
): {
    next: () => void
    prev: () => void
    jump: (page: number) => void
    currentData: () => Array<any>
    currentPage: number
    maxPage: number
} {
    const [currentPage, setCurrentPage] = useState(1)
    const maxPage = Math.ceil(data.length / itemsPerPage)

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage
        const end = begin + itemsPerPage
        return data.slice(begin, end)
    }

    function next() {
        setCurrentPage(Math.min(currentPage + 1, maxPage))
    }

    function prev() {
        setCurrentPage(Math.max(currentPage - 1, 1))
    }

    function jump(page: number) {
        const pageNumber = Math.max(1, page)
        setCurrentPage(Math.min(pageNumber, maxPage))
    }

    return { next, prev, jump, currentData, currentPage, maxPage }
}
