import { ReactElement, useState } from 'react'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import useLogin from '../api/apiHooks/auth/useLogin'
import PaperLogin from './StylingElements/PaperLogin'
import { userStore } from '../App'
import FormInputText from './Form/FormInputText'
import { BackendError } from './Form/BackendError'

export default function Login(): ReactElement {
    const { isLoggedIn } = userStore
    const [loginCallback] = useLogin()
    const [backendError, setBackendError] = useState<string>()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    })

    const onSubmit = (data: any) => {
        loginCallback({
            variables: { username: data.username, password: data.password },
        })
            .then()
            .catch((e) => {
                setBackendError(e.message)
            })
    }

    return (
        <>
            {!isLoggedIn && (
                <form>
                    <PaperLogin>
                        <FormInputText
                            name="username"
                            control={control}
                            label="Username"
                            required
                            errors={errors}
                        />
                        <FormInputText
                            name="password"
                            control={control}
                            label="Password"
                            type="password"
                            required
                            errors={errors}
                        />
                        {backendError && (
                            <BackendError errorMessage={backendError} />
                        )}
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            Submit
                        </Button>
                    </PaperLogin>
                </form>
            )}
        </>
    )
}
