import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { userStore } from '../../App'
import CollectionsOverview from './CollectionsOverview'
import useGetCollectionsFromUser from '../../api/apiHooks/collection/useGetCollectionsFromUser'

function MyCollections(): ReactElement {
    const { isLoggedIn } = userStore
    const [collections] = useGetCollectionsFromUser()

    return (
        <>
            {isLoggedIn && collections && (
                <>
                    <CollectionsOverview
                        collections={collections}
                        headline="Meine Collections"
                    />
                </>
            )}
        </>
    )
}

export default observer(MyCollections)
