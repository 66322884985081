import { ReactElement } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import CollectionListItem from './Collections.partial.collection-list-item'
import { RoutesList } from '../../routes/routes'
import PaperFlex from '../StylingElements/PaperFlex'
import { Collection } from '../../api/types'

type CollectionsOverviewProps = {
    collections: Collection[]
    headline: string
}

export default function CollectionsOverview({
    collections,
    headline,
}: CollectionsOverviewProps): ReactElement {
    const navigate = useNavigate()

    return (
        <>
            <PaperFlex>
                <ListItem
                    onClick={() => navigate(`${RoutesList.AddCollection}`)}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <AddCircleOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Collection hinzufügen" />
                </ListItem>
            </PaperFlex>
            <PaperFlex>
                <Typography sx={{ mt: 0, mb: 2 }} variant="h6" component="div">
                    {headline}
                </Typography>
                {collections &&
                    collections.map((collection) => (
                        <CollectionListItem
                            key={`${collection.id}`}
                            collection={collection}
                        />
                    ))}
            </PaperFlex>
        </>
    )
}
