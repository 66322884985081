import { ReactElement } from 'react'
import {
    alpha,
    AppBar,
    Box,
    Button,
    IconButton,
    InputBase,
    styled,
    Toolbar,
} from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { RoutesList } from '../../routes/routes'
import { userStore } from '../../App'
import HeaderMenu from './Header.menu'

type HeaderProps = {
    darkMode: boolean
    setDarkMode: (state: boolean) => void
}

function Header({ darkMode, setDarkMode }: HeaderProps): ReactElement {
    const navigate = useNavigate()
    const { isLoggedIn } = userStore

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }))

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }))

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }))

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Button
                        color="inherit"
                        variant="text"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                        onClick={() => navigate(RoutesList.Home)}
                    >
                        Home
                    </Button>

                    {isLoggedIn && (
                        <>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                            <Button
                                color="inherit"
                                variant="text"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                                onClick={() =>
                                    navigate(RoutesList.SearchExtended)
                                }
                            >
                                Erw. Suche
                            </Button>
                            <Button
                                color="inherit"
                                variant="text"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                                onClick={() =>
                                    navigate(RoutesList.PublicCollections)
                                }
                            >
                                Collections
                            </Button>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button onClick={() => setDarkMode(!darkMode)} />
                            <IconButton
                                sx={{ ml: 1 }}
                                onClick={() => setDarkMode(!darkMode)}
                                color="inherit"
                            >
                                {darkMode ? (
                                    <Brightness7Icon />
                                ) : (
                                    <Brightness4Icon />
                                )}
                            </IconButton>
                        </>
                    )}
                    <HeaderMenu isLoggedIn={isLoggedIn} navigate={navigate} />
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default observer(Header)
