import { gql } from '@apollo/client'

export function UpdatePicture(): any {
    return gql`
        mutation updatePicture(
            $id: Int!
            $title: String!
            $place: String!
            $event: String!
            $description: String!
            $shot_at: String!
            $photographer: Int
        ) {
            updatePicture(
                id: $id
                title: $title
                place: $place
                event: $event
                description: $description
                shot_at: $shot_at
                photographer: $photographer
            ) {
                id
                title
                source
                place
                event
                description
                shot_at
                updated_at
                updated_by_user_id
                photographer {
                    id
                    firstname
                    lastname
                }
            }
        }
    `
}
