import { ReactElement } from 'react'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FormInputTextProps } from './FormInputProps'

export default function FormInputText({
    name,
    control,
    label,
    disabled,
    rows = 1,
    type = 'text',
    required = false,
    errors,
    multiline = false,
}: FormInputTextProps): ReactElement {
    const isError = errors && errors[name] && errors[name].type === 'required'
    const helperText =
        isError && `${errors[name].ref.name} is ${errors[name].type}`
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field: { onChange, value } }) => (
                <TextField
                    onChange={onChange}
                    value={value}
                    label={label}
                    disabled={disabled}
                    rows={rows}
                    multiline={multiline}
                    type={type}
                    error={isError}
                    helperText={helperText}
                />
            )}
        />
    )
}
