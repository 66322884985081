import { gql, useLazyQuery } from '@apollo/client'
import { UseLazyQueryType } from '../types'

export const LOGIN_WITH_TOKEN = gql`
    query Login($token: String!) {
        login(token: $token) {
            result
            error
            data {
                id
                username
                email
                active
                darkmode
                token
            }
        }
    }
`

export default function useLoginWithToken(): [UseLazyQueryType, any] {
    const [loginWithTokenCallback, { data }] = useLazyQuery(LOGIN_WITH_TOKEN)

    return [loginWithTokenCallback, data]
}
