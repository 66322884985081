import { ReactElement } from 'react'
import { Paper } from '@mui/material'
import { useMutation } from '@apollo/client'
import { removePersonFromPicture } from '../../api/removePersonFromPicture'
import { mapPersonToAutocompleteOption } from '../Form/helpers'
import useGetPersons from '../../api/apiHooks/person/useGetPersons'
import { addPersonToPicture } from '../../api/addPersonToPicture'
import { setErrorMessage, setSuccessMessage } from '../../api/apiHooks/helpers'
import useGetPersonsFromPicture from '../../api/apiHooks/person/useGetPersonsFromPicture'
import FormAutocompleteMultipleValues from '../Form/FormAutocompleteMultipleValues'
import { AutocompleteOption } from '../types'
import { mapOptionToPerson } from '../../helper/personHelpers'
import sortPersons from '../../helper/sortHelpers'

type ManagePersonsProps = {
    pictureId: number
}

export default function ManagePersons({
    pictureId,
}: ManagePersonsProps): ReactElement {
    const [personsFromPicture] = useGetPersonsFromPicture(pictureId)

    const [removePersonFromPictureCallback] = useMutation(
        removePersonFromPicture()
    )
    const [addPersonToPictureCallback] = useMutation(addPersonToPicture)

    const [persons, setPersons] = useGetPersons()

    function onRemoveOptionCallback(option: AutocompleteOption): void {
        const variables = {
            person_id: option.id,
            picture_id: pictureId,
        }
        removePersonFromPictureCallback({ variables }).then((res) => {
            if (res?.data?.removePersonFromPicture?.result) {
                setSuccessMessage(res.data.removePersonFromPicture.result)
            }
            if (res?.data?.removePersonFromPicture?.error) {
                setErrorMessage(res.data.removePersonFromPicture.error)
            }
        })
        if (persons) {
            setPersons(
                [...persons, mapOptionToPerson(option)].sort(sortPersons)
            )
        }
    }

    function onAddOptionCallback(option: AutocompleteOption): void {
        const variables = {
            person_id: option.id,
            picture_id: pictureId,
        }
        addPersonToPictureCallback({ variables }).then((res) => {
            if (res?.data?.addPersonToPicture?.result) {
                setSuccessMessage(res.data.addPersonToPicture.result)
            }
            if (res?.data?.addPersonToPicture?.error) {
                setErrorMessage(res.data.addPersonToPicture.error)
            }
        })
        setPersons(
            persons && persons.filter((person) => person.id !== option.id)
        )
    }

    return (
        <Paper>
            {personsFromPicture && persons && (
                <>
                    <FormAutocompleteMultipleValues
                        id="persons-autocomplete"
                        label="Personen"
                        autocompleteOptions={persons.map(
                            mapPersonToAutocompleteOption
                        )}
                        defaultValue={personsFromPicture.map(
                            mapPersonToAutocompleteOption
                        )}
                        onRemoveOptionCallback={onRemoveOptionCallback}
                        onAddOptionCallback={onAddOptionCallback}
                        disableClearable
                    />
                </>
            )}
        </Paper>
    )
}
