// @ts-ignore
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const { REACT_APP_GRAPHQL_URL } = process.env

const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token')
    // const token = authTokenFromKevin;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token || '',
        },
    }
})

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})
