import React, { ReactElement, useState } from 'react'
import { Button } from '@mui/material'
import {
    SubmitErrorHandler,
    SubmitHandler,
} from 'react-hook-form/dist/types/form'
import { FieldValues } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import FormInputText from '../Form/FormInputText'
import FormRadioGroup from '../Form/FormRadioGroup'
import TransitionsTextModal from '../StylingElements/Modal'

type EditCollectionFormProps = {
    onSubmit: (data: any) => void
    control: any
    handleSubmit: <_>(
        onValid: SubmitHandler<FieldValues>,
        onInvalid?: SubmitErrorHandler<FieldValues>
    ) => (e?: React.BaseSyntheticEvent) => Promise<void>
    headline: string
    onRemove?: (data: any) => void
}

export default function EditCollectionForm({
    onSubmit,
    onRemove,
    control,
    handleSubmit,
    headline,
}: EditCollectionFormProps): ReactElement {
    const [showModal, setShowModal] = useState<boolean>(false)
    const handleCloseModal = () => setShowModal(false)
    return (
        <>
            <Typography sx={{ mt: 0, mb: 2 }} variant="h6" component="div">
                {headline}
            </Typography>
            <FormInputText name="title" control={control} label="Titel" />
            <FormInputText
                name="description"
                control={control}
                label="Beschreibung"
                rows={5}
            />
            <FormRadioGroup
                name="public"
                label="Veröffentlichen"
                control={control}
            />

            <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
            {onRemove && showModal !== undefined && handleCloseModal && (
                <>
                    <Button onClick={() => setShowModal(true)}>
                        Delete Collection
                    </Button>
                    <TransitionsTextModal
                        open={showModal}
                        handleClose={handleCloseModal}
                    >
                        <Typography
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Collection löschen
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 2 }}
                        >
                            Willst du diese Collection wirklich löschen? Die
                            Änderung kann nicht mehr rückgängig gemacht werden.
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 2, flexGrow: 1, textAlign: 'center' }}
                        >
                            <Button
                                onClick={handleSubmit((data: any) => {
                                    onRemove(data)
                                    handleCloseModal()
                                })}
                            >
                                Ja
                            </Button>
                            <Button onClick={handleSubmit(handleCloseModal)}>
                                Nein
                            </Button>
                        </Typography>
                    </TransitionsTextModal>
                </>
            )}
        </>
    )
}
