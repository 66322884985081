import { styled } from '@mui/material'
import { ReactElement } from 'react'

const BackendErrorStyled = styled('div')`
    color: #f44336;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
`

export function BackendError({
    errorMessage,
}: {
    errorMessage: string
}): ReactElement {
    return <BackendErrorStyled>{errorMessage}</BackendErrorStyled>
}
