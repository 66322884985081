import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import {
    forwardRef,
    ReactElement,
    SyntheticEvent,
    useEffect,
    useState,
} from 'react'
import { observer } from 'mobx-react-lite'
import { responseStore } from '../../App'

function Responsebar(): ReactElement | null {
    const {
        successMessage,
        errorMessage,
        warningMessage,
        informationMessage,
        open,
        clearMessages,
    } = responseStore

    const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })
    const [alert, setAlert] = useState<ReactElement<any, any> | undefined>()

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        responseStore.open = false
        clearMessages()
    }

    useEffect(() => {
        if (successMessage) {
            setAlert(
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            )
        }
        if (errorMessage) {
            setAlert(
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            )
        }
        if (warningMessage) {
            setAlert(
                <Alert
                    onClose={handleClose}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    {warningMessage}
                </Alert>
            )
        }
        if (informationMessage) {
            setAlert(
                <Alert
                    onClose={handleClose}
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    {informationMessage}
                </Alert>
            )
        }
    }, [successMessage, errorMessage, informationMessage, warningMessage])

    /*
                <Button variant="outlined" onClick={handleClick}>
                Open success snackbar
            </Button>
     */

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                {alert}
            </Snackbar>
        </Stack>
    )
}

export default observer(Responsebar)
