import { ReactElement } from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import { FormInputDateProps } from './FormInputProps'

export default function FormInputDate({
    name,
    control,
    label,
    defaultValue,
    disabled,
}: FormInputDateProps): ReactElement {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <TextField
                    defaultValue={defaultValue}
                    onChange={onChange}
                    value={value}
                    label={label}
                    disabled={disabled}
                    rows={1}
                    multiline
                />
            )}
        />
    )
}
