import { ReactElement, useState, MouseEvent } from 'react'
import { Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { NavigateFunction } from 'react-router-dom'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { observer } from 'mobx-react-lite'
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import { RoutesList } from '../../routes/routes'
import { userStore } from '../../App'

type HeaderMenuProps = {
    isLoggedIn: boolean
    navigate: NavigateFunction
}

function HeaderMenu({ isLoggedIn, navigate }: HeaderMenuProps): ReactElement {
    const { logout, user } = userStore
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {!isLoggedIn && (
                <Button
                    color="inherit"
                    onClick={() => navigate(RoutesList.Login)}
                >
                    Login
                </Button>
            )}
            {isLoggedIn && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                    open ? 'account-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}
                    >
                        <MenuItem>
                            <Avatar /> {user?.username}
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <PhotoSizeSelectActualOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            Meine Fotos
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate(RoutesList.MyCollections)
                            }}
                        >
                            <ListItemIcon>
                                <PermMediaOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            Meine Collections
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                navigate(RoutesList.UserProfile)
                            }}
                        >
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Einstellungen
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                logout()
                                navigate(RoutesList.Home)
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            )}
        </>
    )
    // return (
    //     <>
    //         {!isLoggedIn && (
    //             <Button
    //                 color="inherit"
    //                 onClick={() => navigate(RoutesList.Login)}
    //             >
    //                 Login
    //             </Button>
    //         )}
    //         {isLoggedIn && (
    //             <div>
    //                 <IconButton
    //                     size="large"
    //                     edge="start"
    //                     color="inherit"
    //                     aria-label="menu"
    //                     sx={{ mr: 2 }}
    //                 >
    //                     <MenuIcon />
    //                 </IconButton>
    //             </div>
    //         )}
    //     </>
    // )
    //         <Button
    //     color="inherit"
    //     onClick={() => {
    //         // localStorage.removeItem('token')
    //         logout()
    //         navigate(RoutesList.Home)
    //     }}
    // >
    //     Logout
    //     </Button>
}

export default observer(HeaderMenu)
