import { AutocompleteOption } from '../types'
import { Collection, Person } from '../../api/types'

export function mapCollectionToAutocompleteOption(
    collection: Collection
): AutocompleteOption {
    return {
        id: collection.id,
        label: collection.title,
        value: collection.title,
    }
}

export function mapPersonToAutocompleteOption(
    person: Person
): AutocompleteOption {
    return {
        id: person.id,
        label: `${person.firstname} ${person.lastname}`,
        value: `${person.firstname} ${person.lastname}`,
    }
}
