import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { Collection } from '../../api/types'
import { userStore } from '../../App'
import CollectionsOverview from './CollectionsOverview'
import useGetCollections from '../../api/apiHooks/collection/useGetCollections'

function PublicCollections(): ReactElement {
    const { isLoggedIn } = userStore

    const [collections] = useGetCollections()

    return (
        <>
            {isLoggedIn && collections && (
                <>
                    <CollectionsOverview
                        collections={collections.filter(
                            (collection: Collection) => collection.public
                        )}
                        headline="Collections"
                    />
                </>
            )}
        </>
    )
}

export default observer(PublicCollections)
