import { gql } from '@apollo/client'
import { apolloClient } from '../../apolloClient'
import { setErrorMessage, setSuccessMessage } from '../helpers'
import { User } from '../../types'

export const UPDATE_USER = gql`
    mutation updateUser(
        $id: Int!
        $username: String
        $password: String
        $email: String
        $darkmode: Boolean
    ) {
        updateUser(
            id: $id
            username: $username
            password: $password
            email: $email
            darkmode: $darkmode
        ) {
            id
            username
            email
            darkmode
            active
        }
    }
`

export default function useUpdateUser(
    isLoggedIn: boolean,
    formValues: User,
    user: User
): void {
    if (isLoggedIn && formValues && user) {
        apolloClient
            .mutate({
                mutation: UPDATE_USER,
                variables: {
                    id: formValues.id,
                    username: formValues.username || null,
                    password: formValues.password || null,
                    email: formValues.email || null,
                    darkmode: formValues.darkmode || null,
                },
            })
            .then((res) => {
                if (!res?.errors && !res?.data?.updateUser?.error) {
                    setSuccessMessage('User details updated successfully')
                }

                if (res?.errors) {
                    setErrorMessage(res.errors[0].message)
                }

                if (res?.data?.updateUser?.error) {
                    setErrorMessage(res.data.addCollectionPicture.error)
                }
            })
    }
}
