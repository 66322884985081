import { gql, useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Picture } from '../../types'
import { userStore } from '../../../App'

export const GET_PICTURES_FROM_COLLECTION = gql`
    query getPicturesFromCollection($id: Int!) {
        getPicturesFromCollection(id: $id) {
            id
            title
            source
            place
            event
            description
            created_at
            shot_at
            updated_at
            created_by_user_id
            updated_by_user_id
        }
    }
`

export default function getPicturesFromCollection(
    id: string
): [Picture[] | null, Dispatch<SetStateAction<Picture[] | null>>] {
    const { isLoggedIn } = userStore
    const [pictures, setPictures] = useState<Picture[] | null>(null)
    const [graphqlCallback] = useLazyQuery(GET_PICTURES_FROM_COLLECTION)

    useEffect(() => {
        if (isLoggedIn) {
            graphqlCallback({
                variables: { id: Number(id) },
            }).then((res) => {
                if (res?.data?.getPicturesFromCollection) {
                    setPictures(res.data.getPicturesFromCollection)
                }
            })
        }
        return () => {
            setPictures(null)
        }
    }, [isLoggedIn])

    return [pictures, setPictures]
}
