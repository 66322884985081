import { gql, useLazyQuery } from '@apollo/client'
import { LOGIN_WITH_TOKEN } from '../auth/useLoginWithToken'
import { UseLazyQueryType } from '../types'

export const ADD_COLLECTION = gql`
    mutation addCollection(
        $title: String!
        $description: String
        $public: Boolean!
    ) {
        addCollection(
            title: $title
            description: $description
            public: $public
        ) {
            result
            error
            data {
                id
                title
                description
                public
                created_at
                updated_at
                created_by_user_id
                updated_by_user_id
            }
        }
    }
`

export default function useAddCollection(): [UseLazyQueryType, any] {
    const [addCollectionCallback, { data }] = useLazyQuery(LOGIN_WITH_TOKEN)

    return [addCollectionCallback, data]
}
