import { ApolloQueryResult, gql } from '@apollo/client'
import { Person } from '../../types'
import { apolloClient } from '../../apolloClient'

export const GET_JOINT_PICTURES_OF_PERSONS = gql`
    query getJointPicturesOfPersons($personIds: [Int]!) {
        getJointPicturesOfPersons(personIds: $personIds) {
            id
            title
            source
            place
            event
            description
            shot_at
            created_at
            updated_at
            created_by_user_id
            updated_by_user_id
        }
    }
`

export default function useGetJointPicturesOfPersons(
    personsToFilter: Person[]
): Promise<ApolloQueryResult<any>> {
    return apolloClient.query({
        query: GET_JOINT_PICTURES_OF_PERSONS,
        variables: {
            personIds: personsToFilter.map((person) => person.id),
        },
    })
}
